import React from "react";
import globalTranslationManager from "./translation_manager";
import _ from "lodash";
import config from "../../../frontend/components/config";

const LocalTranslatedText = ({ text, language }) => {
  const [translated, setTranslated] = React.useState(null);

  // Get the language of the browser.
  const localLanguage = globalTranslationManager.getLocalLanguage();
  const localLanguageCode = globalTranslationManager.getLocalLanguageCode();

  React.useEffect(() => {
    if (_.isString(text) === false) {
      return;
    }

    if (config.REACT_APP_ENABLE_AUTOMATIC_FRONTEND_TRANSLATION === "true") {
      if (
        !language ||
        (language !== localLanguage && language !== localLanguageCode)
      ) {
        globalTranslationManager
          .fetchTranslation(text, localLanguage)
          .then((translation) => {
            setTranslated(translation);
          });
      }
    }
  }, [text, language, localLanguage, localLanguageCode]);

  if (_.isString(text) === false) {
    return text;
  }

  return <>{translated || text}</>;
};

export default LocalTranslatedText;
